import { useNavigate } from 'react-router-dom';
import './AdminPanel.css';
import { useAuth } from '../sign_in/AuthContext';

export default function AdminPanelPage() {
  const { setToken: setTokenContext } = useAuth(); // Access the context
  const navigate = useNavigate();

  const handleLogout = () => {
    setTokenContext(''); // Очистка токена в контексте
    localStorage.removeItem('authToken'); // Удаление токена из localStorage
    navigate('/'); // Перенаправление на страницу входа
  };

  return (
    <div className='main-container'>
        <div className="header">
            <div className="ellipse"></div>
        </div>
      <span className='span'>Mileva</span>
      <span className='span-1'>Managing building:</span>
      <div className='div-2'>
        <div className='div-3'>
          <span className='span-4'>Zvezdarskih jelki 10</span>
        </div>
        <div className='div-5'>
          <div className='div-6' />
        </div>
      </div>
      <div className='div-7'>
        <span className='span-8'>List of tennants</span>
        <div className='div-9'>
          <div className='div-a'>
            <input className='input' />
            <div className='div-b'>
              <div className='div-c' />
            </div>
            <span className='span-d'>Search</span>
          </div>
          <div className='regroup'>
            <button className='button'>
              <div className='frame'>
                <div className='vector' />
              </div>
              <span className='add-tennants'>Add tennants</span>
            </button>
            <button className='button-e'>
              <div className='frame-f' />
              <span className='create-notification'>Create notification</span>
            </button>
            <button className='button-10'>
              <div className='frame-11' />
              <span className='new-poll'>New poll</span>
            </button>
          </div>
        </div>
        <div className='flex-row'>
          <span className='name'>Name</span>
          <span className='jmbg'>JMBG</span>
          <span className='phone'>Phone</span>
          <span className='apt-number'>Apt. number</span>
          <span className='account-status'>Account status</span>
        </div>
        <div className='flex-row-cc'>
          <span className='nikola-jokic'>Nikola Jokic</span>
          <span className='phone-number'>0121231233223</span>
          <span className='phone-number-12'>065 333 5678</span>
          <span className='number'>12</span>
          <span className='active'>Active</span>
          <div className='frame-13'>
            <span className='edit'>Edit</span>
            <div className='frame-14' />
          </div>
          <div className='frame-15'>
            <span className='delete'>Delete</span>
            <div className='frame-16'>
              <div className='vector-17' />
            </div>
          </div>
        </div>
        <div className='line' />
        <div className='flex-row-a'>
          <span className='nikola-jokic-18'>Nikola Jokic</span>
          <span className='phone-number-19'>0121231233223</span>
          <span className='phone-number-1a'>065 333 5678</span>
          <span className='number-1b'>13</span>
          <span className='invited'>Invited</span>
          <div className='frame-1c'>
            <span className='edit-1d'>Edit</span>
            <div className='frame-1e' />
          </div>
          <div className='frame-1f'>
            <span className='delete-20'>Delete</span>
            <div className='frame-21'>
              <div className='vector-22' />
            </div>
          </div>
        </div>
        <div className='line-23' />
        <div className='flex-row-b'>
          <button className='frame-24'>
            <span className='verify'>Verify</span>
          </button>
          <span className='nikola-jokic-25'>Nikola Jokic</span>
          <span className='number-26'>0121231233223</span>
          <span className='phone-number-27'>065 333 5678</span>
          <span className='age'>14</span>
          <div className='frame-28'>
            <span className='edit-29'>Edit</span>
            <div className='frame-2a' />
          </div>
          <div className='frame-2b'>
            <span className='delete-2c'>Delete</span>
            <div className='frame-2d'>
              <div className='vector-2e' />
            </div>
          </div>
        </div>
      </div>
        
    </div>
  );
}

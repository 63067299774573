import React from 'react';
import styles from './Virification.module.css';
import { VerificationCodeForm } from './VerificationCodeForm';
import { useAuth } from '../sign_in/AuthContext';

export const VerificationPage: React.FC = () => {
  const { tmpToken, 
          phoneNumber, 
          K_Key,
          backCounter } = useAuth(); // Retrieve phoneNumber and password from AuthContext

  return (
    <div className={styles.authContainer}>
      <header className={styles.header}>
        <div className={styles.brandContainer}>
          <h1 className={styles.brandName}>Mileva</h1>
          <p className={styles.brandTagline}>for managers</p>
        </div>
        <div className={styles.ellipse}></div>
      </header>
      <VerificationCodeForm 
        tmpToken={tmpToken} 
        phoneNumber={phoneNumber} 
        K_Key={K_Key}
        backCounter={backCounter}
        />
    </div>
  );
};


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.css';
import { useAuth } from './AuthContext';
import { ReactComponent as ShowIcon } from './icons/show-icon.svg';
import { ReactComponent as HideIcon } from './icons/hide-icon.svg';

import base64 from 'base64-js';
import { User, decrypt } from '../srp-lib/srp';


export const SignInForm: React.FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const { setTmpToken: setContextTmpToken, 
          setPhoneNumber: setPhoneNumberContext,
          setK_Key: setK_KeyContext,
          setBackCounter: setBackCounterContext} = useAuth(); // Access the context

  

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, ''); // Убираем все символы, кроме цифр
  
    if (digits.length > 11) {
      return phoneNumber; // Ограничиваем длину до 11 цифр
    }
  
    // Форматируем номер телефона
    const formattedPhone = digits.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,6})$/,
      (_, p1, p2, p3) => [p1 && `+${p1}`, p2, p3].filter(Boolean).join('-')
    );
  
    // Удаляем дефис в конце строки
    return formattedPhone.endsWith('-') ? formattedPhone.slice(0, -1) : formattedPhone;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
  
    // Форматируем номер телефона и удаляем дефис в конце строки
    const formattedValue = formatPhoneNumber(value);
    const cleanedValue = formattedValue.endsWith('-') ? formattedValue.slice(0, -1) : formattedValue;
  
    setPhoneNumber(cleanedValue);
  };

  const handlePhoneFocus = () => {
    setIsPhoneFocused(true);

    // Добавляем "+" в начало строки, если его нет
    if (!phoneNumber.startsWith('+')) {
      setPhoneNumber('+');
    }

    // Перемещаем курсор в конец строки только при фокусе
    const input = document.getElementById('phone') as HTMLInputElement;
    if (input) {
      input.setSelectionRange(phoneNumber.length, phoneNumber.length);
    }
  };

  const handlePhoneBlur = () => {
    setIsPhoneFocused(false);

    // Если в поле остался только "+", очищаем поле
    if (phoneNumber === '+') {
      setPhoneNumber('');
    }
  };

  const handlePhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const selectionStart = input.selectionStart || 0;
    const selectionEnd = input.selectionEnd || 0;
  
    // Предотвращаем перемещение курсора перед плюсом
    if (
      (e.key === 'ArrowLeft' && selectionStart === 1) ||
      (e.key === 'Backspace' && selectionStart === 1)
    ) {
      e.preventDefault();
      input.setSelectionRange(1, 1); // Устанавливаем курсор сразу после плюса
      return;
    }
  
    // Предотвращаем удаление плюса с помощью Backspace
    if (e.key === 'Backspace' && selectionStart === 1 && phoneNumber.startsWith('+')) {
      e.preventDefault();
      return;
    }
  
    // Предотвращаем удаление плюса с помощью Delete
    if (
      e.key === 'Delete' &&
      selectionStart === 0 &&
      phoneNumber.startsWith('+') &&
      (selectionEnd === 1 || phoneNumber.length === 1)
    ) {
      e.preventDefault();
      input.setSelectionRange(1, 1); // Устанавливаем курсор сразу после плюса
      return;
    }
  
    // Удаление только цифр, оставляя плюс, с помощью Backspace
    if (e.key === 'Backspace' && phoneNumber.length > 1 && selectionStart > 1) {
      const updatedPhoneNumber =
        phoneNumber.slice(0, selectionStart - 1) + phoneNumber.slice(selectionEnd);
  
      e.preventDefault();
  
      // Удаляем дефис в конце строки
      const cleanedPhoneNumber = updatedPhoneNumber.endsWith('-')
        ? updatedPhoneNumber.slice(0, -1)
        : updatedPhoneNumber;
  
      setPhoneNumber(cleanedPhoneNumber);
  
      // Устанавливаем курсор на новую позицию после обновления
      setTimeout(() => {
        input.setSelectionRange(selectionStart - 1, selectionStart - 1);
      }, 0);
      return;
    }
  
    // Удаление с помощью Delete только цифр, оставляя плюс
    if (e.key === 'Delete' && phoneNumber.startsWith('+') && selectionStart > 0) {
      const updatedPhoneNumber =
        phoneNumber.slice(0, selectionStart) + phoneNumber.slice(selectionEnd + 1);
  
      e.preventDefault();
  
      // Удаляем дефис в конце строки
      const cleanedPhoneNumber = updatedPhoneNumber.endsWith('-')
        ? updatedPhoneNumber.slice(0, -1)
        : updatedPhoneNumber;
  
      setPhoneNumber(cleanedPhoneNumber);
  
      // Устанавливаем курсор на новую позицию после обновления
      setTimeout(() => {
        input.setSelectionRange(selectionStart, selectionStart);
      }, 0);
      return;
    }
  };
  
  

  const handlePhoneSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;

    // Если выделение начинается с плюса, сбрасываем выделение
    if (input.selectionStart !== null && input.selectionStart === 0) {
      input.setSelectionRange(1, input.selectionEnd);
    }
  };

  const validateForm = () => {
    let isValid = true;

    const phoneRegex = /^\+\d{3}-\d{2}-\d{6}$/;
    const digitCount = phoneNumber.replace(/\D/g, '').length;

    if (!phoneRegex.test(phoneNumber) || digitCount < 11) {
      isValid = false;
    }

    if (password.length < 6) {
      isValid = false;
    }

    setHasError(!isValid); // Устанавливаем общий флаг ошибки
    setErrorMessage(isValid ? '' : 'Phone number or password is incorrect');

    return isValid;
  };

 
   

  async function step1(
    phoneNumber: string,
    password: string,
    baseUrl: string
  ): Promise<{ user: User; A: string; s: Buffer; B: bigint } | null> {
     
    // Initialize SRP User
    const user = new User(phoneNumber, password); 
    const A = user.getPublicEphemeral();    
   
    const A_64 = base64.fromByteArray(Buffer.from(A.toString(16).padStart(256, "0"), "hex"));
  
    // Prepare request payload
    const url = `${baseUrl}/auth/signin/step1`;
    const data = { phone_number: phoneNumber, A: A_64 };
       
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  
    console.log("Response status:", response.status);
    if (response.ok) {
      const responseData = await response.json();
      const { s, B } = responseData;
    
      // Decode salt and server public key
      const decodedS = Buffer.from(s, "base64"); // Salt in binary format
      const decodedB = BigInt(`0x${Buffer.from(B, "base64").toString("hex")}`); // Server public key as bigint
    
      // Return User object and server data for Step 2
      return { user, A: A_64, s: decodedS, B: decodedB };
    } else if (response.status === 400) {
      setHasError(true);
      setErrorMessage('Please try again later'); // Specific message for 400 status code
      return null;
    } else {
      setHasError(true);
      setErrorMessage('Phone number or password is incorrect');
      return null; // Step 1 failed
    }
  }
  
  async function step2(
    user: User,
    step1Data: { s: Buffer; B: bigint },
    baseUrl: string
  ): Promise<{ 
      isAuthenticated: boolean; 
      tmpToken?: string; 
      K_Key?: string; 
      timeout?: number 
  }> {
    const { s, B } = step1Data;
  
    try {
      const M = user.processChallenge(s, B);
      const M_base64 = M.toString("base64");
  
      // Send M to the server
      const url = `${baseUrl}/auth/signin/step2`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone_number: user.getUsername(), M: M_base64 }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
  
        const K_Key = user.getSessionKey().toString("base64"); 
        const HAMK_server = Buffer.from(responseData.HAMK, "base64");
        const tmpToken = responseData.tmp_token;
  
        const timeout = responseData.timeout
          ? Number(decrypt(K_Key, responseData.timeout, "timeout"))
          : undefined;
          
        const status = responseData.status
          ? decrypt(K_Key, responseData.status, "status")
          : undefined;
  
        console.log("tmp_token:", tmpToken);
        console.log("timeout:", timeout);
        console.log("status:", status);
  
        // Verify the server-provided HAMK
        const isValid = user.verifySession(HAMK_server);
  
        if (isValid) {
          console.log("USER AUTHENTICATED: true");
  
          return { isAuthenticated: true, tmpToken, K_Key, timeout }; // Возвращаем timeout
        } else {
          setHasError(true);
          setErrorMessage("Phone number or password is incorrect");
          console.error("USER AUTHENTICATED: false. HAMK mismatch.");
          return { isAuthenticated: false };
        }
      } else {
        setHasError(true);
        setErrorMessage("Phone number or password is incorrect");
        console.error("Step 2 failed:", await response.text());
        return { isAuthenticated: false };
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage("Authentication error. Please try again");
      console.error("Error in step2:", error);
      return { isAuthenticated: false };
    }
  }
  
      
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    const username = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
  
    try {
      const step1Result = await step1(username, password, "https://mileva.rs/admin");
  
      if (step1Result) {
        const { user, s, B } = step1Result;
        const { isAuthenticated, tmpToken, K_Key, timeout } = await step2(user, { s, B }, "https://mileva.rs/admin");
  
        if (isAuthenticated && tmpToken && K_Key && timeout) {
          setContextTmpToken(tmpToken); // Save tmpToken in context
          setPhoneNumberContext(username); // Save phone number in context
          setK_KeyContext(K_Key); 
          setBackCounterContext(timeout);
  
          navigate("/confirm_code");
          console.log("Authentication successful!");
        } else {
          setHasError(true);
          setErrorMessage("Phone number or password is incorrect");
          console.error("Authentication failed.");
        }
      }
    } catch (error) {
      console.error("Error during SRP authentication:", error);
      setHasError(true);
      setErrorMessage("Authentication error. Please try again");
    }
  };
  
  
  

  const handleFocus = () => {
    setHasError(false); // Сбрасываем общий флаг ошибки
    setErrorMessage(''); // Убираем сообщение об ошибке
  };

  return (
    <form className={styles.signInForm} onSubmit={handleSubmit}>
      <h1 className={styles.signInTitle}>Sign in</h1>

      <div className={styles.formFields}>
        {/* Поле ввода телефона */}
        <div className={styles.inputContainer}>
          <label htmlFor="phone" className={styles.inputLabel}>
            Phone number
          </label>
          <input
            id="phone"
            type="tel"
            placeholder={isPhoneFocused ? '' : '+XXX-XX-XXXXXX'}
            className={`${styles.inputField} ${hasError ? styles.inputError : ''}`}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={handlePhoneFocus}
            onBlur={handlePhoneBlur}
            onKeyDown={handlePhoneKeyDown}
            onSelect={handlePhoneSelect} // Запрещаем выделение плюса
            maxLength={15}
          />
        </div>

        {/* Поле ввода пароля */}
        <div className={styles.passwordContainer}>
          <label htmlFor="password" className={styles.passwordLabel}>
            Password
          </label>
          <div className={styles.passwordWrapper}>
            <input
              id="password"
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your password"
              className={`${styles.passwordField} ${hasError ? styles.inputError : ''}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={handleFocus}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={styles.visibilityButton}
              aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
            >
              {isPasswordVisible ? <ShowIcon /> : <HideIcon />}
            </button>
          </div>
        </div>

        {/* Объединённое сообщение об ошибке */}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        <button type="button" className={styles.forgotPassword}>
          Forgot your password?
        </button>

        <button type="submit" className={styles.signInButton}>
          Sign in
        </button>
      </div>
    </form>
  );
};

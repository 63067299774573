import React, { createContext, useState, useContext, useEffect } from 'react';

interface AuthContextProps {
  tmpToken: string;
  token: string; // Добавляем token
  K_Key: string;
  phoneNumber: string;
  backCounter: number;
  setTmpToken: (value: string) => void;
  setToken: (value: string) => void; // Setter для token
  setK_Key: (value: string) => void;
  setPhoneNumber: (value: string) => void;
  setBackCounter: (value: number) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tmpToken, setTmpToken] = useState('');
  const [token, setToken] = useState(() => {
    // Загружаем токен из Local Storage
    return localStorage.getItem('token') || '';
  });
  const [K_Key, setK_Key] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [backCounter, setBackCounter] = useState(0);

  // Сохраняем token в Local Storage при его изменении
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token'); // Удаляем токен, если он пуст
    }
  }, [token]);

  return (
    <AuthContext.Provider 
      value={{
        tmpToken,
        token,
        K_Key,
        phoneNumber,
        backCounter,
        setTmpToken,
        setToken,
        setK_Key,
        setPhoneNumber,
        setBackCounter,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import styles from './Virification.module.css';
import { ReactComponent as ReplaceIcon } from './replace-icon.svg';
import { encryptAES, decrypt } from '../srp-lib/srp';
import { useAuth } from '../sign_in/AuthContext';

interface VerificationCodeFormProps {
  tmpToken: string;
  phoneNumber: string;
  K_Key: string;
  backCounter: number
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
  tmpToken,
  phoneNumber,
  K_Key,
  backCounter
}) => {
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(backCounter); // Timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setTmpToken: setTmpTokenContext, 
          setToken: setTokenContext
        } = useAuth(); // Access the context
  const navigate = useNavigate(); // Хук для безопасной навигации

   // Convert seconds to mm:ss format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Update the timer
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup the interval
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setCode(value);
      setHasError(false);
      setErrorMessage('');
    }
  };

  const handleCodeFocus = () => {
    setHasError(false);
    setErrorMessage('');
  };

  const handleResendCode = async () => {
    
    setIsResendDisabled(true);
    setCode('');

    try {
      const url = `https://mileva.rs/admin/auth/resend`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tmp_token: tmpToken}),
      });
  
      if (!response.ok) {
        console.error("Server responded with an error:", response.status);
        setHasError(true);
        setErrorMessage('Invalid request. Please try again.');
        return;
      }
  
      let data;
      try {
        data = await response.json();
      } catch (error) {
        console.error("Error parsing JSON response:", error);
        setHasError(true);
        setErrorMessage('Invalid server response. Please try again.');
        return;
      }

      const token = data.token 
        ? decrypt(K_Key, data.token, "token") 
        : undefined;
        
      const statusResponse = data.status
        ? decrypt(K_Key, data.status, "status")
        : undefined;

      const timeoutResponse = data.timeout
        ? Number( decrypt(K_Key, data.timeout, "timeout"))
        : 310;       

      setTimer(timeoutResponse);

      console.log("Decrypted server response:", { token, statusResponse, timeoutResponse });

      if (statusResponse !== 'OTP_RESENDED') {
        setErrorMessage('Failed to resend code. Please try again.');
        setHasError(true);
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
      setHasError(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!/^\d{6}$/.test(code)) {
      setHasError(true);
      setErrorMessage('Invalid code. Must be exactly 6 digits.');
      return;
    }
  
    const sendCode = encryptAES(K_Key, code);
    if (!sendCode) {
      setHasError(true);
      setErrorMessage('Encryption failed. Unable to send the code.');
      return;
    }
  
    console.log("Encrypted OTP code:", sendCode);
    console.log("Payload:", { tmp_token: tmpToken, otp_code: sendCode });
  
    try {
      const url = `https://mileva.rs/admin/auth/confirm`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tmp_token: tmpToken, otp_code: sendCode }),
      });
  
      if (!response.ok) {
        console.error("Server responded with an error:", response.status);
        setHasError(true);
        setErrorMessage('Invalid code. Please try again.');
        return;
      }
  
      let data;
      try {
        data = await response.json();
      } catch (error) {
        console.error("Error parsing JSON response:", error);
        setHasError(true);
        setErrorMessage('Invalid server response. Please try again.');
        return;
      }
  
      const token = data.token 
        ? decrypt(K_Key, data.token, "token") 
        : undefined;

      const statusResponse = data.status
        ? decrypt(K_Key, data.status, "status")
        : undefined;
  
      console.log("Decrypted server response:", { token, statusResponse });
  
      if (statusResponse === 'OTP_APPROVED') {
        setHasError(false);
        setErrorMessage('');

        setTmpTokenContext(''); // Очищаем tmpToken
        setTokenContext(token ?? ''); // Если token равен undefined, используется пустая строка
        localStorage.setItem('authToken', token ?? ''); // Сохраняем token в localStorage

        navigate('/panel'); // Навигация на защищённую страницу
      } else {
        setHasError(true);
        setErrorMessage('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error("Error during OTP confirmation:", error);
      setHasError(true);
      setErrorMessage('Something went wrong. Please try again.');
    }
  };
  
  

  return (
    <form className={styles.signInForm} onSubmit={handleSubmit}>
      <h1 className={styles.signInTitle}>Enter SMS code</h1>
      <p className={styles.inputLabel}>
        We’ve sent an SMS with an activation code to your phone <br />
        <strong className={styles.phoneNumber}>+{phoneNumber}</strong>
      </p>

      <div className={styles.formFields}>
        {/* Code input field */}
        <div className={styles.inputContainer}>
          <label htmlFor="smsCode" className={styles.inputLabel}>
            SMS code
          </label>
          <input
            id="smsCode"
            type="text"
            placeholder="000000"
            className={`${styles.inputField} ${hasError ? styles.inputError : ''}`}
            value={code}
            onChange={handleCodeChange}
            onFocus={handleCodeFocus}
            maxLength={6}
          />
          {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
        </div>

        {/* Timer or Resend button */}
        <p className={styles.timerContainer}>
          {timer > 0 ? (
            <span className={styles.timer}>
              Send again in {formatTime(timer)}
            </span>
          ) : (
            <button
              type="button"
              className={styles.resendButton}
              onClick={handleResendCode}
              disabled={isResendDisabled}
            >
              Resend
              <ReplaceIcon className={styles.replaceIcon} />
            </button>
          )}
        </p>

        {/* Confirm button */}
        <button type="submit" className={styles.signInButton}>
          Confirm
        </button>
      </div>
    </form>
  );
};
